import React, { Component } from "react";
import { Helmet } from "react-helmet";

function MarketingEnterpriseCK() {
  return (
    <Helmet>
      <meta
        property="og:url"
        content="https://divivgroup.lpages.co/marketing-for-enterprises-ck/"
      />
      <link
        rel="opengraph"
        href="https://divivgroup.lpages.co/marketing-for-enterprises-ck/"
      />
      <script src="//divivgroup.lpages.co/_/js/marketing-for-enterprises-ck/"></script>
      <style>
        {
          'html{font-family:"Roboto", sans-serif} strong { color: inherit; } section{ padding: 10px} a:hover{text-decoration:none} #___gatsby, #gatsby-focus-wrapper{display:none}'
        }
      </style>
    </Helmet>
  );
}

export default MarketingEnterpriseCK;
